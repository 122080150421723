import './App.css';
import Header from './Component/Header';
import Footer from './Component/Footer';
import StakeNFT from './Component/StakeNFT';
import Home from './Component/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import ScrollToTop from "./Component/ScrollToTop";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyStake from './Component/MyStake';
import UnstakeToken from './Component/UnstakeToken';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop>
          <ToastContainer pauseOnFocusLoss={false} />
          <Header />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/stake-nft">
              <StakeNFT />
            </Route>
            <Route exact path="/my-stake">
              <MyStake />
            </Route>
            <Route exact path="/unstake">
              <UnstakeToken />
            </Route>
          </Switch>
          <Footer />
        </ScrollToTop>
      </Router>
    </>
  );
}

export default App;
