import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import tokenAbi from '../json/token.json';
import oceanNftAbi from '../json/oceanNft.json';
import stakingAbi from '../json/staking.json';
import { contract, DEFAULT_CHAIN } from "./constant";
import { MulticallContractWeb3 } from "../hooks/useContracts";
import { getWeb3 } from "../hooks/connectors";
import { toast } from "react-toastify";


export const useMyStakeAccountStats = (updater) => {
    let { account, chainId } = useWeb3React();
    let web3 = getWeb3(DEFAULT_CHAIN);

    let oceansNftContract = new web3.eth.Contract(oceanNftAbi, contract[DEFAULT_CHAIN].OCEANS_NFT_ADDRESS);
    let oceansStakingContract = new web3.eth.Contract(stakingAbi, contract[DEFAULT_CHAIN].OCEANS_STAKING);
    let busdContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].BUSD_ADDRESS);
    let oceanContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].OCEANS_ADDRESS);


    const [stats, setStats] = useState({
        busdBal: 0,
        busdApprove: false,
        busdDecimals: 0,
        totalvalue: 0,
        totalapy: 0,
        stakeTime: 0,
        lastClaimed: 0,
        unlockvalue: 0,
        walletOfOwner: [],
        tokenURI: [],
        getEarning : 0


    });

    const mc = MulticallContractWeb3(DEFAULT_CHAIN);


    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    busdContract.methods.balanceOf(account), //0
                    busdContract.methods.allowance(account, contract[DEFAULT_CHAIN].OCEANS_NFT_ADDRESS), //1
                    busdContract.methods.decimals(), //2
                    oceansNftContract.methods.balanceOf(account),//3
                    oceansStakingContract.methods._userDB(account), //4
                    oceanContract.methods.decimals(), //5
                    oceansStakingContract.methods.getEarning(account), //6
                    oceansNftContract.methods.walletOfOwner(account),//7,
                ]);
                
                
                let nftUri = [];
                if (data[7].length > 0) {
                    let call_array = [];
                    data[7].map((value, index) => {
                        call_array[index] = oceansNftContract.methods.tokenURI(value);
                        return value;
                    })

                    nftUri = await mc.aggregate(call_array);
                }

                
                setStats({
                    busdBal: data[0] / Math.pow(10, data[2]),
                    busdApprove: parseFloat(data[1] / Math.pow(10, data[2])) > 500000 ? true : false,
                    busdDecimals: data[2],
                    totalvalue: parseFloat(data[4][0] / Math.pow(10, data[5])) + parseFloat(data[4][4] / Math.pow(10, data[5])) ,
                    totalapy: data[4][1],
                    stakeTime: data[4][2],
                    lastClaimed: data[4][3],
                    unlockvalue: data[4][4] / Math.pow(10, data[5]),
                    walletOfOwner: data[7],
                    tokenURI: nftUri,
                    getEarning : data[6] / Math.pow(10, data[5])
                    // stakeId : nftUri.length > 0 ? nftUri.map((value)=>{
                    //     let split = value.split('/').pop();
                    //     return split.toString().replace('.json','');
                    // }) : []
                })
            }
            catch (err) {
                console.log(err.message)
                toast.error(err.reason ? err.reason : err.message);
            }
        }

        if (mc && account) {
            fetch();
        }
        else {
            setStats({
                busdBal: 0,
                busdApprove: false,
                busdDecimals: 0,
                totalvalue: 0,
                totalapy: 0,
                stakeTime: 0,
                lastClaimed: 0,
                unlockvalue: 0,
                walletOfOwner: [],
                tokenURI: [],
            })
        }
        // eslint-disable-next-line
    }, [updater, account, chainId]);

    return stats;
}
