import React from "react";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import {
    NoEthereumProviderError
} from "@web3-react/injected-connector";
import { useEffect, useState } from "react";
import { injected, walletconnect, coinbaseWallet, bscwallet } from "../hooks/connectors";
import Modal from 'react-bootstrap/Modal';
import { trimAddress } from '../hooks/constant';
import useEagerConnect from '../hooks/useWeb3';
import metamaskIcon from '../assets/img/metamask.svg';
import binanceIcon from '../assets/img/binance.svg';
import coinbaseIcon from '../assets/img/coinbase.svg';
import trustwalletIcon from '../assets/img/trustwallet.svg';
import walletconnectIcon from '../assets/img/walletconnect.svg';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import { useNftAccountStats } from "../hooks/useNftStake";
import { formatPrice } from "../hooks/contractHelper";
import { CopyToClipboard } from "react-copy-to-clipboard";
import localStorage from "local-storage";


export const Connect = function () {
    const context = useWeb3React();
    const { connector, account, activate, deactivate, active, error } = context;
    const [show, setShow] = useState(false);
    const [walletshow, setWalletShow] = useState(false);
    let accStats = useNftAccountStats();
    const [refcopy, setRefcopy] = useState(false);
    let txHistory = localStorage.get('txhistory');


    // handle logic to recognize the connector currently being activated
    const [activatingConnector, setActivatingConnector] = useState();
    useEagerConnect();
    useEffect(() => {
        if (activatingConnector && activatingConnector === connector) {
            setActivatingConnector(undefined);
        }
    }, [activatingConnector, connector]);


    function getErrorMessage(error) {

        if (error instanceof NoEthereumProviderError) {
            return "Metamask not deteced";
        }
        if (error instanceof UnsupportedChainIdError) {
            return <span className="btn-text" onClick={(e) => switchNetwork(56)}>
                <img src={require("../assets/img/heartbeat.png").default} alt="wrong-network" height="17px" width="17px" className="mx-2" />Wrong Network</span>;
        }

        deactivate(injected);
    }

    const activating = (connection) => connection === activatingConnector;
    const connected = (connection) => connection === connector;

    const switchNetwork = (networkid) => {
        try {
            // @ts-ignore
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: `0x${networkid.toString(16)}` }]
            });
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <li className="nav-item dropdown">

                {
                    error &&
                    <button type="button" className="btn btn-custom" onClick={() => {
                        setActivatingConnector();
                    }}>
                        {getErrorMessage(error)}
                    </button>
                }
                {!error &&
                    <>


                        {active && (connected(injected) || connected(walletconnect) || connected(coinbaseWallet)) &&
                            <>
                                <a className="nav-link dropdown-toggle wallet-add" href="#sec" role="button"
                                    data-toggle="dropdown" aria-expanded="false">
                                    <div className="wallet-icon">
                                        <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" className=" ACFFk">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"></path>
                                        </svg>
                                    </div>
                                    <div className="w-add">{account && trimAddress(account)}</div>
                                    <svg viewBox="0 0 24 24" color="text" width="24px" xmlns="http://www.w3.org/2000/svg" className=" iGEvSN">
                                        <path d="M8.11997 9.29006L12 13.1701L15.88 9.29006C16.27 8.90006 16.9 8.90006 17.29 9.29006C17.68 9.68006 17.68 10.3101 17.29 10.7001L12.7 15.2901C12.31 15.6801 11.68 15.6801 11.29 15.2901L6.69997 10.7001C6.30997 10.3101 6.30997 9.68006 6.69997 9.29006C7.08997 8.91006 7.72997 8.90006 8.11997 9.29006Z"></path>
                                    </svg>
                                </a>
                                <ul className="dropdown-menu wallet-drop">
                                    <li>
                                        <a className="dropdown-item" href="#sec" onClick={() => {
                                            setWalletShow(!walletshow);
                                        }} >
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>Wallet</span>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#sec" onClick={() => {
                                            setWalletShow(!walletshow);
                                        }}>Transactions</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#sec" onClick={() => {
                                            setActivatingConnector();
                                            deactivate(injected);
                                            deactivate(walletconnect);
                                            deactivate(coinbaseWallet);

                                        }}>
                                            <span style={{ "marginRight": "10px" }}>Disconnect</span>
                                            <svg viewBox="0 0 24 24" color="text" width="20px" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM iGEvSN"><path d="M16.3 8.09014C15.91 8.48014 15.91 9.10014 16.3 9.49014L18.2 11.3901H9C8.45 11.3901 8 11.8401 8 12.3901C8 12.9401 8.45 13.3901 9 13.3901H18.2L16.3 15.2901C15.91 15.6801 15.91 16.3001 16.3 16.6901C16.69 17.0801 17.31 17.0801 17.7 16.6901L21.29 13.1001C21.68 12.7101 21.68 12.0801 21.29 11.6901L17.7 8.09014C17.31 7.70014 16.69 7.70014 16.3 8.09014ZM4 19.3901H11C11.55 19.3901 12 19.8401 12 20.3901C12 20.9401 11.55 21.3901 11 21.3901H4C2.9 21.3901 2 20.4901 2 19.3901V5.39014C2 4.29014 2.9 3.39014 4 3.39014H11C11.55 3.39014 12 3.84014 12 4.39014C12 4.94014 11.55 5.39014 11 5.39014H4V19.3901Z"></path></svg>
                                        </a>
                                    </li>
                                </ul>
                            </>
                            // <button type="button" className="btn btn-custom" onClick={() => {
                            //     setActivatingConnector();
                            //     deactivate(injected);
                            //     deactivate(walletconnect);
                            //     deactivate(coinbaseWallet);
                            // }}>{account && trimAddress(account)}
                            // </button>
                        }
                        {!active && (!connected(injected) || !connected(walletconnect) || !connected(coinbaseWallet)) &&
                            <button type="button" className="btn btn-custom" onClick={() => {
                                setShow(!show);
                            }}>
                                {(activating(injected) || activating(walletconnect) || activating(coinbaseWallet)) && <span className="btn-text">Connecting...</span>}
                                {(!activating(injected) || !activating(walletconnect) || !activating(coinbaseWallet)) && <span className="btn-text"><i className="icon-wallet mr-md-2"></i>Connect Wallet</span>}

                            </button>
                        }
                    </>
                }
            </li>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="ms"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <h5 className="modal-title" id="exampleModalLabel">Connect Wallet</h5>
                    <button type="button" className="btn-close" onClick={() => setShow(false)} >
                        <svg viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM ACFFk">
                            <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"></path>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="wallet-list">
                        <div className="wallet-grid">
                            <div className="wallet-grid-list">
                                <button className="wallet-box" id="wallet-connect-metamask" width="100%" scale="md" onClick={() => {
                                    activate(injected);
                                    setShow(false);
                                }}>
                                    <img src={metamaskIcon} alt="metamask" />
                                    <div className="wallet-title">Metamask</div>
                                </button>
                            </div>
                            <div className="wallet-grid-list">
                                <button className="wallet-box" id="wallet-connect-metamask" width="100%" scale="md" onClick={() => {
                                    activate(walletconnect);
                                    setShow(false);
                                }}>
                                    <img src={walletconnectIcon} alt="walletconnect" />
                                    <div className="wallet-title">WalletConnect</div>
                                </button>
                            </div>
                            <div className="wallet-grid-list">
                                <button className="wallet-box" id="wallet-connect-metamask" width="100%" scale="md" onClick={() => {
                                    activate(injected);
                                    setShow(false);
                                }}>
                                    <img src={trustwalletIcon} alt="trustwalletIcon" />
                                    <div className="wallet-title">Trust Wallet</div>
                                </button>
                            </div>
                            <div className="wallet-grid-list">
                                <button className="wallet-box" id="wallet-connect-metamask" width="100%" scale="md" onClick={() => {
                                    activate(bscwallet);
                                    setShow(false);
                                }}>
                                    <img src={binanceIcon} alt="binanceIcon" />
                                    <div className="wallet-title">Binance Chain</div>
                                </button>
                            </div>
                            <div className="wallet-grid-list">
                                <button className="wallet-box" id="wallet-connect-metamask" width="100%" scale="md" onClick={() => {
                                    activate(coinbaseWallet);
                                    setShow(false);
                                }}>
                                    <img src={coinbaseIcon} alt="coinbaseIcon" />
                                    <div className="wallet-title">Coinbase</div>
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <p>Haven’t got a crypto wallet yet?</p>
                    <a href="#section" target="_blank">Learn How to Connect</a>
                </Modal.Footer> */}
            </Modal>

            <Modal
                show={walletshow}
                onHide={() => setWalletShow(false)}
                size="ms"
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header>
                    <h5 className="modal-title" id="exampleModalLabel">Your Wallet</h5>
                    <button type="button" className="btn-close" onClick={() => setWalletShow(false)} >
                        <svg viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM ACFFk">
                            <path d="M18.3 5.70997C17.91 5.31997 17.28 5.31997 16.89 5.70997L12 10.59L7.10997 5.69997C6.71997 5.30997 6.08997 5.30997 5.69997 5.69997C5.30997 6.08997 5.30997 6.71997 5.69997 7.10997L10.59 12L5.69997 16.89C5.30997 17.28 5.30997 17.91 5.69997 18.3C6.08997 18.69 6.71997 18.69 7.10997 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.10997C18.68 6.72997 18.68 6.08997 18.3 5.70997Z"></path>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='tabs'>
                        <Tabs defaultActiveKey="first">
                            <Tab eventKey="first" title="Wallet">
                                <div className="ffFSSX">Your Address</div>
                                <div className="cRnGBx">
                                    <div className="jcNvwq jBkJSA">
                                        <div className="bHVaei"><input type="text" readOnly="" value={account ? account : ''} /></div>

                                        <button className="eKidLY sc-eCApnc fAYopv" scale="md">
                                            <CopyToClipboard text={account} onCopy={() => {
                                                setRefcopy(true);
                                                setTimeout(() => {
                                                    setRefcopy(false);
                                                }, 2000)
                                            }}>
                                                <svg viewBox="0 0 24 24" color="primary" width="24px" xmlns="http://www.w3.org/2000/svg" className="ACFFk">
                                                    <path d="M15 1H4C2.9 1 2 1.9 2 3V16C2 16.55 2.45 17 3 17C3.55 17 4 16.55 4 16V4C4 3.45 4.45 3 5 3H15C15.55 3 16 2.55 16 2C16 1.45 15.55 1 15 1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM18 21H9C8.45 21 8 20.55 8 20V8C8 7.45 8.45 7 9 7H18C18.55 7 19 7.45 19 8V20C19 20.55 18.55 21 18 21Z"></path>
                                                </svg>
                                            </CopyToClipboard>
                                        </button>
                                    </div>

                                    {refcopy && <div className="ddsIyr">Copied</div>}

                                </div>
                                {/* <div className="ckegkQ">
                                    <div className="hwtEBz">
                                        <svg viewBox="0 0 24 24" color="warning" width="24px" xmlns="http://www.w3.org/2000/svg" className="fRwzTT">
                                            <path d="M4.47 20.9999H19.53C21.07 20.9999 22.03 19.3299 21.26 17.9999L13.73 4.98993C12.96 3.65993 11.04 3.65993 10.27 4.98993L2.74 17.9999C1.97 19.3299 2.93 20.9999 4.47 20.9999ZM12 13.9999C11.45 13.9999 11 13.5499 11 12.9999V10.9999C11 10.4499 11.45 9.99993 12 9.99993C12.55 9.99993 13 10.4499 13 10.9999V12.9999C13 13.5499 12.55 13.9999 12 13.9999ZM13 17.9999H11V15.9999H13V17.9999Z"></path>
                                        </svg>
                                    </div>
                                </div> */}
                                <div className="eqGhLO">
                                    <div color="textSubtle" className="bmnHHk">BNB Balance</div>
                                    <div color="text" className="hmxDlj">{formatPrice(parseFloat(accStats.getEthBalance).toFixed(3))}</div>
                                </div>
                                <div className="eqGhLO">
                                    <div color="textSubtle" className="bmnHHk">OCEANS Balance</div>
                                    <div color="text" className="hmxDlj">{formatPrice(parseFloat(accStats.balance).toFixed(3))}</div>
                                </div>
                                <div className="gEZgPl lksWUy">
                                    <a target="_blank" rel="noreferrer" href={`https://bscscan.com/address/${account}`} color="primary" className="sc-cOifOu iiWEXc haQoIp">
                                        View on BscScan
                                        <svg viewBox="0 0 24 24" color="primary" width="20px" xmlns="http://www.w3.org/2000/svg" className="iXahuZ">
                                            <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path>
                                        </svg>
                                    </a>
                                </div>
                                <button className="sc-hKFxyN dpJhGx" width="100%" onClick={() => {
                                    setActivatingConnector();
                                    deactivate(injected);
                                    deactivate(walletconnect);
                                    deactivate(coinbaseWallet);
                                    setWalletShow(false)
                                }} >Disconnect Wallet</button>
                            </Tab>
                            <Tab eventKey="second" title="Transactions">
                                <div className="gEZgPl eqGhLO">
                                    <div className="eWUbJG">Recent Transactions</div>
                                    <button className="ljYdKG" onClick={(e) => {localStorage.clear(); setWalletShow(false); }}>Clear all</button>
                                </div>
                                {txHistory && txHistory.length > 0 && txHistory.map((value, index) => {
                                    return (
                                        <a target="_blank" rel="noreferrer noopener" href={`https://bscscan.com/tx/${value.hash}`} className="eKgOla jYknmU jZmHBX">
                                            <div className="dUUCVU bHahRd gxkQyu">
                                                <svg viewBox="0 0 24 24" color="success" width="24px" xmlns="http://www.w3.org/2000/svg" className="cQAKax">
                                                    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15.88 8.29L10 14.17L8.12 12.29C7.73 11.9 7.1 11.9 6.71 12.29C6.32 12.68 6.32 13.31 6.71 13.7L9.3 16.29C9.69 16.68 10.32 16.68 10.71 16.29L17.3 9.7C17.69 9.31 17.69 8.68 17.3 8.29C16.91 7.9 16.27 7.9 15.88 8.29Z"></path>
                                                </svg>
                                            </div>
                                            <div className="kCjwoq">{value.type === 'approve' ? `Approve ${value.currency}` : `${value.amount} ${value.currency} for ${value.type}`}</div>
                                            <div className="dUUCVU bHahRd gxkQyu">
                                                <svg viewBox="0 0 24 24" width="24px" color="primary" xmlns="http://www.w3.org/2000/svg" className="wNVai">
                                                    <path d="M18 19H6C5.45 19 5 18.55 5 18V6C5 5.45 5.45 5 6 5H11C11.55 5 12 4.55 12 4C12 3.45 11.55 3 11 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V13C21 12.45 20.55 12 20 12C19.45 12 19 12.45 19 13V18C19 18.55 18.55 19 18 19ZM14 4C14 4.55 14.45 5 15 5H17.59L8.46 14.13C8.07 14.52 8.07 15.15 8.46 15.54C8.85 15.93 9.48 15.93 9.87 15.54L19 6.41V9C19 9.55 19.45 10 20 10C20.55 10 21 9.55 21 9V4C21 3.45 20.55 3 20 3H15C14.45 3 14 3.45 14 4Z"></path>
                                                </svg>
                                            </div>
                                        </a>
                                    )
                                })}
                                {/* 
							<div className="euwgKs">No recent transactions</div>
							*/}
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>

            </Modal>


        </React.Fragment >
    );
};

export default Connect;