import React, { useState } from 'react'
import { useMyStakeAccountStats } from '../hooks/useMystake';
import { useTokenInfoStats } from '../hooks/useTokenInfo';
import { useNftAccountStats } from '../hooks/useNftStake';
import { formatPrice } from '../hooks/contractHelper';
import Button from 'react-bootstrap-button-loader';
import { DEFAULT_CHAIN } from '../hooks/constant';
import { getContract } from '../hooks/contractHelper';
import { ethers } from 'ethers';
import { contract } from '../hooks/constant';
import stakingAbi from '../json/staking.json';
import { toast } from 'react-toastify';
import { getWeb3 } from '../hooks/connectors';
import { useWeb3React } from '@web3-react/core';
import { transactionHistory } from '../hooks/constant';


export default function UnstakeToken() {
    const [updater, setUpdater] = useState(new Date());
    const accState = useMyStakeAccountStats(updater);
    const tokenState = useNftAccountStats(updater);

    const oceanState = useTokenInfoStats(updater);
    const [amount, setAmount] = useState(0);
    const [btndisabled, setBtndisabled] = useState(false);
    const [error_msg, setError_msg] = useState('');
    const [loading, setLoading] = useState(false);
    const { chainId, account, library } = useWeb3React();

    const handleChangeAmount = (e) => {
        setAmount(e.target.value);
        setBtndisabled(true);

        if (isNaN(e.target.value)) {
            setError_msg('please enter valid amount');
            setBtndisabled(true);

        }

        else if (parseFloat(e.target.value) === 0 || e.target.value === '') {
            setError_msg('amount must be greater than zero');
            setBtndisabled(true);

        }
        else {
            setError_msg('');
            setBtndisabled(false);
        }
        return;
    }

    const handleMaxAmount = (e) => {
        e.preventDefault();
        setAmount(parseFloat(accState.unlockvalue).toFixed(3));
        setError_msg('');
        setBtndisabled(false);
    }

    const handleUnStake = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (amount > 0 && !isNaN(amount)) {
                if (account) {
                    if (chainId === DEFAULT_CHAIN) {
                        if (parseFloat(accState.unlockvalue) >= parseFloat(amount)) {
                            let stakingAddress = contract[DEFAULT_CHAIN].OCEANS_STAKING;
                            let stakeContract = getContract(stakingAbi, stakingAddress, library);
                            let stakeAmount = ethers.utils.parseUnits(amount.toString(), oceanState.decimals);
                            const estimation = await stakeContract.estimateGas.unstake(stakeAmount.toString());
                            let gasLimit = parseInt(estimation.toString()) + 100000;
                            let tx = await stakeContract.unstake(stakeAmount.toString(), { 'from': account, gasLimit });
                            const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                            toast.promise(
                                resolveAfter3Sec,
                                {
                                    pending: 'Waiting for confirmation 👌',
                                }
                            )
                            transactionHistory(tx.hash, 'unstake', amount, 'OCEANS');
                            var interval = setInterval(async function () {
                                let web3 = getWeb3(chainId);
                                var response = await web3.eth.getTransactionReceipt(tx.hash);
                                if (response != null) {
                                    clearInterval(interval)
                                    if (response.status === true) {
                                        toast.success('success ! your last transaction is success 👍');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else if (response.status === false) {
                                        toast.error('error ! Your last transaction is failed.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                    else {
                                        toast.error('error ! something went wrong.');
                                        setUpdater(new Date());
                                        setLoading(false);
                                    }
                                }
                            }, 5000);

                        }
                        else {
                            toast.error('you don\'t have enough balance !');
                            setLoading(false);
                        }

                    }
                    else {
                        toast.error('Please select Smart Chain Network !');
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please Connect Wallet!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Enter Valid Amount !');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }

    return (
        <div className="container">
            <div className="row mt-5">
                <div className="col-sm-12 col-md-12 col-lg-7">
                    <div className="grid-full mb-5">
                        <div className="text-center">
                            <h4>UNSTAKE OCEANS</h4>
                        </div>
                        <hr />
                        <div className="d-flex align-items-center justify-content-between mt-3">
                            <div className="text-primary">OCEANS</div>
                            <div className="">Balance {accState.unlockvalue ? formatPrice(parseFloat(accState.unlockvalue).toFixed(3)) : 0} OCEANS</div>
                            <button className="buttonRed btn" onClick={(e) => handleMaxAmount(e)}>Max</button>
                        </div>
                        <div className="card mt-1">
                            <div className="card-body">
                                <input type="text" name="amount" value={amount} onChange={(e) => { handleChangeAmount(e) }} placeholder="Enter amount" />
                                <p className='text-sm mt-1 text-right text-danger'>{error_msg}</p>
                            </div>
                        </div>
                        <div className="mt-3 text-center">
                            <Button loading={loading} variant="none" disabled={btndisabled} className="btn btn-custom" onClick={(e) => handleUnStake(e)}>UNSTAKE</Button>
                        </div>
                        <div className="mt-3 text-center">
                            <small className='text-danger'>Note : APY will reduce as per total unstake amount percentage</small> 
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-5">
                    <div className="grid-full ">
                        <div className="text-center">
                            <h5>Your OCEANS Balance</h5>
                        </div>
                        <div className="mt-10 text-left flex flex-wrap">
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">OCEANS Balance</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {tokenState.balance ? formatPrice(parseFloat(tokenState.balance).toFixed(3)) : 0} OCEANS
                                </div>
                            </div>
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">BUSD Balance</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {tokenState.busdBal ? formatPrice(parseFloat(tokenState.busdBal).toFixed(3)) : 0} BUSD
                                </div>
                            </div>
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">Staked</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {accState.totalvalue ? formatPrice(parseFloat(accState.totalvalue).toFixed(3)) : 0} OCEANS
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="text-left flex flex-wrap">
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">Your APY</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {accState.totalapy ? formatPrice(parseFloat(accState.totalapy).toFixed(3)) : 0}%
                                </div>
                            </div>
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">Last Claimed</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {accState.lastClaimed > 0 ? new Date(parseInt(accState.lastClaimed * 1000)).toUTCString() : ' - '}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
