import image1 from './assets/img/nft-1.png';
import image2 from './assets/img/nft-2.png';
import image3 from './assets/img/nft-3.png';
import image4 from './assets/img/nft-4.png';
import image5 from './assets/img/nft-5.png';
import image6 from './assets/img/nft-6.png';
import image7 from './assets/img/nft-7.png';
import image8 from './assets/img/nft-8.png';
import image9 from './assets/img/nft-9.png';
import image10 from './assets/img/nft-10.png';

export const NftList = [
    {
        id : 1,
        name : "Blow Fish",
        apy : "100%",
        image : image1,
        price : 100,
        range : 200,
        priceMark : [10,25,50,100,200] 
    },
    {
        id : 2,
        name : "Tuna Fish",
        apy : "200%",
        image : image2,
        price : 300,
        range : 600,
        priceMark : [30,75,150,300,600] 
    },
    {
        id : 3,
        name : "Shark",
        apy : "300%",
        image : image3,
        price : 900,
        range : 1800,
        priceMark : [100,200,450,900,1800] 
    },
    {
        id : 4,
        name : "Pucker",
        apy : "400%",
        image : image4,
        price : 1500,
        range : 3000,
        priceMark : [150,375,750,1500,3000] 
    },
    {
        id : 5,
        name : "Oyster",
        apy : "500%",
        image : image5,
        price : 2100,
        range : 4200,
        priceMark : [310,775,1550,3000,4200] 
    },
    {
        id : 6,
        name : "Squid",
        apy : "600%",
        image : image6,
        price : 2800,
        range : 5600,
        priceMark : [300,800,1600,2800,5600] 
    },
    {
        id : 7,
        name : "Rock Fish",
        apy : "700%",
        image : image7,
        price : 3000,
        range : 6000,
        priceMark : [300,800,1600,3000,6000] 
    },
    {
        id : 8,
        name : "Crap",
        apy : "800%",
        image : image8,
        price : 3700,
        range : 7400,
        priceMark : [600,1200,2500,5000,7400]
    },
    {
        id : 9,
        name : "Halibut",
        apy : "900%",
        image : image9,
        price : 4200,
        range : 8400,
        priceMark : [600,1200,2500,5000,8400]
    },
    {
        id : 10,
        name : "Sea Horse",
        apy : "1000%",
        image : image10,
        price : 5000,
        range : 10000,
        priceMark : [600,1200,2500,5000,10000]
    }
]

