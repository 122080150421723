import { contract, DEFAULT_CHAIN } from '../hooks/constant';
import { supportNetwork } from "./network";
import Multicall from '@dopex-io/web3-multicall';
import multiCallAbi from '../json/multicall.json';
import { getWeb3 } from "./connectors";
import routerAbi from '../json/RouterAbi.json';
import { ethers } from 'ethers';




export const MulticallContractWeb3 = (chainId) => {
  let multicallAddress = contract[chainId] ? contract[chainId].MULTICALL_ADDRESS : contract['default'].MULTICALL_ADDRESS;

  let provider = supportNetwork[chainId] ? supportNetwork[chainId].rpc : supportNetwork['default'].rpc;


  const multicall = new Multicall({
    multicallAddress,
    provider
  });

  return multicall;

}


export const multiCallContractConnect = (chainId) => {
  let multicallAddress = contract[chainId] ? contract[chainId].MULTICALL_ADDRESS : contract['default'].MULTICALL_ADDRESS;
  let web3 = getWeb3(chainId);
  return new web3.eth.Contract(multiCallAbi, multicallAddress);
}

export const getBusdTokenConvert = async (amount) => {
  try {
    let web3 = getWeb3(DEFAULT_CHAIN);
    let routerContract = new web3.eth.Contract(routerAbi, contract[DEFAULT_CHAIN].PANCAKE_ROUTER);
    let getAmountOut = await routerContract.methods.getAmountsOut(ethers.utils.parseUnits(parseFloat(amount).toString(), 18), [contract[DEFAULT_CHAIN].BUSD_ADDRESS, contract[DEFAULT_CHAIN].WBNB_ADDRESS, contract[DEFAULT_CHAIN].OCEANS_ADDRESS]).call() //6
    return getAmountOut[2] / Math.pow(10,5); 
  }
  catch(err){
    console.log(err.message);
    return false;
  }
}
