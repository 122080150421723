import React, { useEffect, useState } from 'react';
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { NftList  } from '../constant';
import { formatPrice , getContract } from '../hooks/contractHelper';
import { useNftAccountStats } from '../hooks/useNftStake';
import { useTokenInfoStats } from '../hooks/useTokenInfo';
import { FEES_PER } from '../hooks/constant';
import Button from 'react-bootstrap-button-loader';
import { contract, DEFAULT_CHAIN } from '../hooks/constant';
import { toast } from 'react-toastify';
import { useWeb3React } from "@web3-react/core";
import tokenAbi from '../json/token.json';
import oceanNftAbi from '../json/oceanNft.json';
import { parseEther } from '@ethersproject/units';
import { getWeb3 } from '../hooks/connectors';
import { ethers } from 'ethers';
import { transactionHistory } from '../hooks/constant';
import { useMyStakeAccountStats } from '../hooks/useMystake';
import { getBusdTokenConvert } from '../hooks/useContracts';


const StakeNFT = () => {
    const { chainId, account, library } = useWeb3React();
    const [selected, setSelected] = useState(1);
    const [extraValue, setExtraValue] = useState(0);
    const [totalCost, setTotalCost] = useState(NftList[selected-1].price ? NftList[selected-1].price : 0);
    const [tokenReceive, setTokenReceive] = useState(0);
    const [valueApy, setValueApy] = useState(0);
    const [loading, setLoading] = useState(false);
    const [updater, setUpdater] = useState(new Date());
    const accState = useNftAccountStats(updater);
    const tokenState = useTokenInfoStats(updater);
    const nftBuyState = useMyStakeAccountStats(updater);

    useEffect(() => {
        async function fecth(){
            let totalBusd = parseFloat(NftList[selected-1].price) + parseFloat(extraValue);
            let afterFeesBusd = FEES_PER <= 100 ? parseFloat(NftList[selected-1].price * parseFloat(100 - FEES_PER) / 100) : 0;
            let totalInvest = parseFloat(afterFeesBusd) + parseFloat(extraValue)
            let countOcenas = await getBusdTokenConvert(totalInvest);
            if(countOcenas){
                setTokenReceive(countOcenas);
            }
            setTotalCost(totalBusd);
            setValueApy(totalInvest * parseFloat(NftList[selected-1].apy) / 100)
        }
        fecth();
    }, [extraValue, selected, tokenState])


    const handleApproveBusd = async (e) => {
        e.preventDefault();
        if (account) {
            if (chainId === DEFAULT_CHAIN) {
                try {

                    setLoading(true);
                    let BusdAddress = contract[DEFAULT_CHAIN].BUSD_ADDRESS;
                    let oceansNftContract = contract[DEFAULT_CHAIN].OCEANS_NFT_ADDRESS;
                    let busdContract = getContract(tokenAbi, BusdAddress, library);
                    let amount = parseEther('5000000').toString();

                    let tx = await busdContract.approve(oceansNftContract, amount, { 'from': account });
                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )
                    transactionHistory(tx.hash,'approve',0,'BUSD');
                    var interval = setInterval(async function () {
                        let web3 = getWeb3(DEFAULT_CHAIN);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(new Date());
                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Smart Chain Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    const handleMint = async (e) => {
        e.preventDefault();
        if (account) {
            if (chainId === DEFAULT_CHAIN) {
                if (parseFloat(accState.busdBal) >= parseFloat(totalCost)) {
                    try {
                        setLoading(true);
                        let oceansNftAddress = contract[DEFAULT_CHAIN].OCEANS_NFT_ADDRESS;
                        let oceansNftContract = getContract(oceanNftAbi, oceansNftAddress, library);
                        let stakeAmount = ethers.utils.parseUnits(totalCost.toString(), accState.busdDecimals);
                        const estimation = await oceansNftContract.estimateGas.mint(account, NftList[selected-1].id, stakeAmount);
                        let gasLimit = parseFloat(estimation.toString()) + 50000;
                        let tx = await oceansNftContract.mint(account, NftList[selected-1].id, stakeAmount, { 'from': account, gasLimit });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation',
                            }
                        )
                        transactionHistory(tx.hash,'Mint', parseFloat(totalCost) , 'BUSD');
                        var interval = setInterval(async function () {
                            let web3 = getWeb3(DEFAULT_CHAIN);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success 👍');
                                    setLoading(false);
                                    setUpdater(new Date());
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                    setUpdater(new Date());
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                    setUpdater(new Date());
                                }
                            }
                        }, 5000);
                    }
                    catch (err) {
                        toast.error(err.reason);
                        setLoading(false);
                    }
                }
                else {
                    toast.error('You don\'t have enough BUSD balance!!!');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please select Smart Chain Network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!');
            setLoading(false);
        }
    }

    return (
        <>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-12 col-lg-11 text-center">
                        <h1 className="nft-title mt-5 mb-2">SELECT NFT</h1>
                        <p className="mb-5 text-sm"> To start earning you need to select and buy an NFT. Please click on the NFT of your choosing. The NFT will determine the APY you will receive. </p>
                        <h3 className="nft-subtitle">To start, select your NFT level </h3>
                        <div className="mt-4 d-flex justify-conten-center flex-wrap mb-5">
                            {NftList && NftList.length > 0 &&
                                NftList.map((value, index) => {
                                    let checkExist = nftBuyState.tokenURI.length > 0 ? nftBuyState.tokenURI.some((rowdata)=>{
                                            let split = rowdata.split('/').pop();
                                            let id = parseInt(split.toString().replace('.json', ''));
                                            return id === value.id
                                        }) : false;
                                
                                    return (
                                        <div className="w-1/3 sm:w-1/5 md:w-1/5 p-1 lg:p-3" key={index}>
                                            <div className={`w-full d-flex baseDiv ${selected === value.id || checkExist ? 'baseDivActive' : ''}`} onClick={(e) => {
                                                setSelected(value.id ? value.id : 1)
                                                setExtraValue(0)
                                            }}>
                                                <div className="nftCard relative rounded-t-xl sm:rounded-t-2xl rounded-b-lg sm:rounded-t-2xl sm:rounded-b-2xl">
                                                    <img src={value.image} alt="Level 1" className="nftImage" />
                                                    <div className="nftOverlay"></div>
                                                    <div className="relative bottom-0 w-full bottom-0 text-center flex flex-wrap justify-center content-center pt-1">
                                                        <div className="text-md md:text-lg font-light w-full p-1">
                                                            <p className='mb-1'>{value.name ? value.name : ''}</p>
                                                            <p className="mb-1">{value.apy ? value.apy : ''}</p>
                                                            {checkExist && <p className="mb-1">(Purchased)</p> }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='bonus-area'>
                            <h1 className="mt-10 font-bold text-xl">As a bonus we will gift you 10% of the NFT value in tokens. <span> To increase your outcome, add additional tokens to your NFT. </span></h1>
                        </div>
                        <div className="mt-4 flex flex-wrap justify-center">
                            {selected && NftList[selected-1]?.priceMark.map((priceValue, index) => {
                                return (
                                    <div className="w-1/5 p-1 md:p-3 flex justify-center" key={index}>
                                        <div className={`w-full sm:p-4 flex flex-wrap justify-center content-center tokensButton text-sm md:text-lg lg:text-xl text-center ${extraValue === priceValue ? 'tokensButtonActive' : ''}`} onClick={(e) => { setExtraValue(priceValue) }}><span>{formatPrice(parseFloat(priceValue))} <br />BUSD</span></div>
                                    </div>
                                )
                            })}

                        </div>
                        {/* range slider start */}
                        <Slider
                            progress
                            min={0}
                            max={NftList[selected-1] ? NftList[selected-1].range : 0}
                            defaultValue={0}
                            value={extraValue}
                            renderTooltip={value => `${formatPrice(parseFloat(value))} BUSD`}
                            onChange={value => {
                                setExtraValue(value);
                            }} className="mt-5"
                        />

                        <div className="flex justify-between w-full mt-1 p-2">
                            <span>0 BUSD</span>
                            <span>{NftList[selected-1] ? formatPrice(parseFloat(NftList[selected-1].range)) : 0} BUSD</span>
                        </div>
                        {/* range slider over */}
                        <div className="mt-10 card p-4 sm:p-10">
                            <div className="text-xl font-bold sm:text-4xl mt-3"> Current selection </div>
                            <div className="mt-10 text-left flex flex-wrap">
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">NFT price</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right"><span className="busd">{NftList[selected-1] ? formatPrice(parseFloat(NftList[selected-1].price)) : 0}</span></div>
                                </div>
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">Additional tokens price</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right"><span className="busd">{formatPrice(parseFloat(extraValue).toFixed(3))}</span></div>
                                </div>
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">Total price</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right"><span className="busd">{formatPrice(parseFloat(totalCost).toFixed(3))}</span></div>
                                </div>
                            </div>
                            <hr className="mt-3 mb-3" />
                            <div className="text-xl font-bold sm:text-4xl mt-3"> You will get </div>
                            <div className="mt-10 text-left flex flex-wrap">
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">APY level</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right">{NftList[selected-1] ? NftList[selected-1].apy : 0}</div>
                                </div>
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">Total token staked ( approx )</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right">{formatPrice(parseFloat(tokenReceive).toFixed(5))} OCEANS</div>
                                </div>
                            </div>
                            <hr className="mt-3 mb-3" />
                            <div className="text-xl font-bold sm:text-4xl"> Future value </div>
                            <div className="mt-10 text-left flex flex-wrap">
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">6 Months</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right">${formatPrice(parseFloat(valueApy / 2).toFixed(3))}</div>
                                </div>
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">1 Year</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right">${formatPrice(parseFloat(valueApy).toFixed(3))}</div>
                                </div>
                                <div className="mt-1 w-full flex flex-wrap">
                                    <div className="text-sm sm:text-md select-none">2 years</div>
                                    <div className="flex-grow font-medium sm:text-lg text-right">${formatPrice(parseFloat(valueApy * 2).toFixed(3))}</div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 mb-5">
                            <div className="w-full mb-3">
                                Balance : {formatPrice(parseFloat(accState.busdBal).toFixed(3))} <span className="busd"> </span>
                            </div>
                            {
                                accState.busdApprove ? (
                                    <div className="w-full">
                                        <Button variant="none" loading={loading} className="py-2 px-4 md:text-xl md:py-3 md:px-4 buttonRed" onClick={(e) => { handleMint(e) }}>Buy NFT</Button>
                                    </div>
                                ) : (
                                    <React.Fragment>

                                        <div className="w-full">
                                            <Button variant="none" loading={loading} className="py-2 px-4 md:text-xl md:py-3 md:px-4 buttonRed" onClick={(e) => { handleApproveBusd(e) }}>Approve BUSD</Button>
                                        </div>
                                        <div className="mt-4">
                                            <span>After approving BUSD you will need to complete your purchase here in a second step.</span>
                                        </div>
                                    </React.Fragment>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default StakeNFT