import { useEffect, useState } from "react"
import tokenAbi from '../json/token.json';
import DistributorAbi from '../json/Distributor.json';
import IERC20Abi from '../json/IERC20.json';
import PairContractAbi from '../json/PairContract.json';
import { contract, DEFAULT_CHAIN } from "./constant";
import { MulticallContractWeb3  } from "../hooks/useContracts";
import { getWeb3 } from "../hooks/connectors";
import { toast } from "react-toastify";


export const useTokenInfoStats = (updater) => {
  let web3 = getWeb3(DEFAULT_CHAIN);

  let oceansContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].OCEANS_ADDRESS);
  let distributorContract = new web3.eth.Contract(DistributorAbi, contract[DEFAULT_CHAIN].DISTRIBUTOR_ADDRESS);
  let wbnbContract = new web3.eth.Contract(IERC20Abi, contract[DEFAULT_CHAIN].WBNB_ADDRESS);
  let oceanPairContract = new web3.eth.Contract(PairContractAbi, contract[DEFAULT_CHAIN].OCEANS_BNB_LP_ADDRESS);
  let bnbPairContract = new web3.eth.Contract(PairContractAbi, contract[DEFAULT_CHAIN].WBNB_USDT_LP_ADDRESS);
  let busdPairContract = new web3.eth.Contract(PairContractAbi, contract[DEFAULT_CHAIN].USDT_BUSD_LP_ADDRESS);


  const [stats, setStats] = useState({
    totalSupply: 0,
    decimals: 0,
    getCirculatingSupply: 0,
    rebaseFrequency: 0,
    nextRebase: 0,
    bnbPrice: 0,
    oceanPrice: 0,
    marketCap: 0,
    liquidity: 0,
    totalDistibutedBusd: 0,
    totalDistibuted: 0,
    totalAPY: 0,
    DailyAPY: 0
  });

  const mc = MulticallContractWeb3(DEFAULT_CHAIN);


  useEffect(() => {
    const fetch = async () => {
      try {
        const data = await mc.aggregate([
          oceansContract.methods.decimals(), //0
          oceansContract.methods.totalSupply(), //1
          oceansContract.methods.getCirculatingSupply(), // 2
          oceansContract.methods.rebaseFrequency(), //3
          oceansContract.methods.nextRebase(), //4
          oceanPairContract.methods.getReserves(), //5
          bnbPairContract.methods.getReserves(), //6
          wbnbContract.methods.balanceOf(contract[DEFAULT_CHAIN].OCEANS_BNB_LP_ADDRESS), //7
          distributorContract.methods.totalDistributed(), //8
          busdPairContract.methods.getReserves(), //9
          oceansContract.methods.rewardYield(), //10,
          oceansContract.methods.balanceOf(contract[DEFAULT_CHAIN].OCEANS_BNB_LP_ADDRESS) //11
        ]);


        setStats({
          decimals: data[0],
          totalSupply: data[1] / Math.pow(10, data[0]),
          getCirculatingSupply: data[2] / Math.pow(10, data[0]),
          rebaseFrequency: data[3],
          nextRebase: data[4],
          bnbPrice: data[6][0] / data[6][1],
          oceanPrice: ((data[6][0] / data[6][1]) * (data[5][1] / data[5][0])) / Math.pow(10, 18 - data[0]),
          marketCap: (data[6][0] / data[6][1]) * (data[5][1] / data[5][0]) / Math.pow(10, 18 - data[0]) * data[2] / Math.pow(10, data[0]),
          liquidity: ((data[7] / Math.pow(10, 18)) * (data[6][0] / data[6][1])) + ((data[11] / Math.pow(10,data[0])) * (((data[6][0] / data[6][1]) * (data[5][1] / data[5][0])) / Math.pow(10, 18 - data[0]))) ,
          totalDistibutedBusd: data[8] / Math.pow(10, 18),
          totalDistibuted: (data[9][1] / data[9][0]) * (data[8] / Math.pow(10, 18)),
          totalAPY: Math.pow(1 + (data[10] / 10000000), 365 * 48) - 1,
          DailyAPY: Math.pow(1 + (data[10] / 10000000), 1 * 48) - 1

        })
      }
      catch (err) {
        console.log(err.message);
        toast.error(err.reason)

      }
    }

    if (mc) {
      fetch();
    }
    else {
      setStats({
        totalSupply: 0,
        decimals: 0,
        getCirculatingSupply: 0,
        rebaseFrequency: 0,
        nextRebase: 0,
        bnbPrice: 0,
        oceanPrice: 0,
        marketCap: 0,
        liquidity: 0,
        totalDistibutedBusd: 0,
        totalDistibuted: 0,
        totalAPY: 0,
        DailyAPY: 0
      })
    }
    // eslint-disable-next-line
  }, [updater]);

  return stats;
}
