import React from "react";
import { useTokenInfoStats } from "../hooks/useTokenInfo";

const Footer = () => {
    const tokenStats = useTokenInfoStats(1);
    return (
        <>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-4 col-md-4 col-lg-4 order-md-1 text-lg-end text-start">
                            <img src={require('../assets/img/logo-full.png').default} alt="Ocean Logo" className='foo-logo' />
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                            <ul className="foo-list">
                                <li>About</li>
                                <li>
                                    <a href="https://whitepaper.oceans.finance/into-the-sea/the-deep-blue">Contact</a>
                                </li>
                                <li>
                                    <a href="#section">Community</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-4 col-md-4 col-lg-4">
                            <ul className="foo-list">
                                <li>HELP</li>
                                <li>
                                    <a href="https://whitepaper.oceans.finance/into-the-sea/the-deep-blue">Document</a>
                                </li>
                                <li>
                                    <a href="#section">FAQ</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="row mt-5 align-items-center">
                        <div className="col-6 col-sm-6 col-lg-6">
                            <div className="foo-social">
                                <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/oceans_finance" aria-label="Twitter" color="primary">
                                    <svg viewBox="0 0 18 15" width="20px" color="#B8ADD2" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM eNPaSS">
                                        <path d="M5.659 15c6.79 0 10.507-5.766 10.507-10.763 0-.16 0-.32-.01-.49A7.578 7.578 0 0018 1.79c-.663.3-1.376.5-2.127.6a3.824 3.824 0 001.63-2.1c-.713.44-1.503.75-2.352.92A3.6 3.6 0 0012.46 0C10.419 0 8.76 1.699 8.76 3.787c0 .3.039.58.098.86-3.064-.15-5.786-1.669-7.61-3.957A3.858 3.858 0 00.75 2.598c0 1.31.654 2.469 1.64 3.148a3.638 3.638 0 01-1.669-.47v.05c0 1.83 1.278 3.368 2.956 3.708-.312.09-.634.13-.976.13-.234 0-.468-.02-.692-.07.468 1.509 1.834 2.598 3.453 2.628a7.284 7.284 0 01-4.585 1.62c-.293 0-.595-.01-.878-.05A10.206 10.206 0 005.659 15z"></path>
                                    </svg>
                                </a>
                                <a target="_blank" rel="noreferrer noopener" href="https://t.me/ocean_finances" aria-label="telegram" color="primary">
                                    <svg viewBox="0 0 20 20" width="20px" color="#B8ADD2" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM elXZTS">
                                        <path d="M10 0C4.478 0 0 4.478 0 9.99 0 15.511 4.478 20 10 20s10-4.488 10-10.01C20 4.477 15.522 0 10 0zm4.925 6.28c-.064.927-1.78 7.856-1.78 7.856s-.107.406-.48.416a.644.644 0 01-.49-.192c-.395-.33-1.29-.97-2.132-1.556a.953.953 0 01-.107.096c-.192.17-.48.416-.789.714a10.7 10.7 0 00-.373.352l-.01.01a2.214 2.214 0 01-.193.171c-.415.341-.458.053-.458-.096l.224-2.441v-.021l.01-.022c.011-.032.033-.043.033-.043s4.36-3.88 4.477-4.296c.01-.021-.021-.042-.074-.021-.288.096-5.31 3.273-5.864 3.625-.032.02-.128.01-.128.01l-2.441-.8s-.288-.117-.192-.383c.021-.053.053-.107.17-.181.544-.384 10-3.785 10-3.785s.267-.085.427-.032c.074.032.117.064.16.17.01.043.021.128.021.224 0 .054-.01.118-.01.224z"></path>
                                    </svg>
                                </a>
                                <a target="_blank" rel="noreferrer noopener" href="https://github.com/oceansfinance/" aria-label="Github" color="primary">
                                    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" width="20px" color="#B8ADD2" className="sc-bdnxRM eNPaSS">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M9.97 0C4.464 0 0 4.465 0 9.97c0 4.403 2.875 8.134 6.789 9.48.49.06.673-.245.673-.49v-1.712c-2.753.611-3.364-1.346-3.364-1.346-.428-1.162-1.101-1.468-1.101-1.468-.917-.611.061-.611.061-.611.979.06 1.53 1.04 1.53 1.04.917 1.528 2.323 1.1 2.874.856a2.13 2.13 0 01.611-1.346c-2.201-.245-4.526-1.1-4.526-4.954 0-1.101.367-1.957 1.04-2.691-.061-.184-.428-1.223.122-2.569 0 0 .857-.245 2.753 1.04.795-.245 1.651-.306 2.507-.306.857 0 1.713.122 2.508.306 1.896-1.285 2.752-1.04 2.752-1.04.55 1.346.184 2.385.123 2.63a3.956 3.956 0 011.04 2.691c0 3.853-2.325 4.648-4.527 4.893.367.306.673.918.673 1.835v2.752c0 .245.184.55.673.49A9.98 9.98 0 0020 9.97C19.939 4.464 15.474 0 9.97 0z"></path>
                                    </svg>
                                </a>
                                <a target="_blank" rel="noreferrer noopener" href="https://discord.com/invite/m8An2vHZ6A" aria-label="Discord" color="primary" className="sc-gtsrHT sc-cOifOu heXOmK haQoIp">
                                    <svg viewBox="0 0 20 16" width="20px" color="#B8ADD2" xmlns="http://www.w3.org/2000/svg" className="sc-bdnxRM eNPaSS">
                                        <path d="M16.93 1.644A16.491 16.491 0 0012.86.38a.062.062 0 00-.066.031c-.175.313-.37.72-.506 1.041a15.226 15.226 0 00-4.573 0A10.54 10.54 0 007.2.412a.064.064 0 00-.065-.031 16.447 16.447 0 00-4.07 1.263.058.058 0 00-.028.023C.444 5.54-.266 9.319.083 13.05a.069.069 0 00.026.047 16.584 16.584 0 004.994 2.525.064.064 0 00.07-.023c.385-.526.728-1.08 1.022-1.662a.063.063 0 00-.035-.088 10.917 10.917 0 01-1.56-.744.064.064 0 01-.007-.106c.105-.079.21-.16.31-.243a.062.062 0 01.065-.009c3.273 1.495 6.817 1.495 10.051 0a.062.062 0 01.066.008c.1.083.204.165.31.244a.064.064 0 01-.005.106c-.499.291-1.017.537-1.561.743a.064.064 0 00-.034.089c.3.582.643 1.135 1.02 1.66a.063.063 0 00.07.025 16.53 16.53 0 005.003-2.525.064.064 0 00.026-.046c.417-4.314-.699-8.061-2.957-11.384a.05.05 0 00-.026-.023zM6.684 10.778c-.985 0-1.797-.905-1.797-2.016 0-1.11.796-2.015 1.797-2.015 1.01 0 1.814.912 1.798 2.015 0 1.111-.796 2.016-1.798 2.016zm6.646 0c-.986 0-1.797-.905-1.797-2.016 0-1.11.796-2.015 1.797-2.015 1.009 0 1.813.912 1.797 2.015 0 1.111-.788 2.016-1.797 2.016z"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className='col-6 col-sm-6 col-lg-6'>
                            <div className='d-flex align-items-lg-center flex-column justify-content-lg-end justify-content-start flex-lg-row foo-buttons'>
                                <div className='bYgUJS'>
                                    <img src={require('../assets/img/logo-sm.png').default} alt="" />
                                    <div className="hgdLNj">${parseFloat(tokenStats.oceanPrice).toFixed(5)}</div>
                                </div>
                                <a href='https://oceanswap.io/swap?inputCurrency=BNB&outputCurrency=0x2A54F9710ddeD0eBdde0300BB9ac7e21cF0E8DA5' target="_blank" rel="noreferrer"  className="fQbIXd">Buy <svg viewBox="0 0 24 24" color="#FFFFFF" width="20px" xmlns="http://www.w3.org/2000/svg" className="inIxCT"><path d="M5 13H16.17L11.29 17.88C10.9 18.27 10.9 18.91 11.29 19.3C11.68 19.69 12.31 19.69 12.7 19.3L19.29 12.71C19.68 12.32 19.68 11.69 19.29 11.3L12.71 4.7C12.32 4.31 11.69 4.31 11.3 4.7C10.91 5.09 10.91 5.72 11.3 6.11L16.17 11H5C4.45 11 4 11.45 4 12C4 12.55 4.45 13 5 13Z"></path></svg></a>
                            </div>

                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer