import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { formatPrice } from '../hooks/contractHelper';
import { useMyStakeAccountStats } from '../hooks/useMystake';
import { useNftAccountStats } from '../hooks/useNftStake';
import { NftList } from '../constant';
import { DEFAULT_CHAIN } from '../hooks/constant';
import { getContract } from '../hooks/contractHelper';
import { contract } from '../hooks/constant';
import stakingAbi from '../json/staking.json';
import { toast } from 'react-toastify';
import { getWeb3 } from '../hooks/connectors';
import { useWeb3React } from '@web3-react/core';
import Button from 'react-bootstrap-button-loader';
import { transactionHistory } from '../hooks/constant';
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "react-simple-tooltip"


export default function MyStake() {
    const { chainId, account, library } = useWeb3React();
    const [updater, setUpdater] = useState(new Date());
    const accState = useMyStakeAccountStats(updater);
    const tokenState = useNftAccountStats(updater);
    const [loading, setLoading] = useState(false);


    const handleClaimReward = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {

            if (account) {
                if (chainId === DEFAULT_CHAIN) {
                    if (parseFloat(accState.getEarning) > 0) {
                        let stakingAddress = contract[DEFAULT_CHAIN].OCEANS_STAKING;
                        let stakeContract = getContract(stakingAbi, stakingAddress, library);
                        const estimation = await stakeContract.estimateGas.claimReward();
                        let gasLimit = parseFloat(estimation.toString()) + 100000;
                        let tx = await stakeContract.claimReward({ 'from': account, gasLimit });
                        const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 10000));
                        toast.promise(
                            resolveAfter3Sec,
                            {
                                pending: 'Waiting for confirmation 👌',
                            }
                        )
                        transactionHistory(tx.hash, 'claim reward', accState.getEarning, 'OCEANS');
                        var interval = setInterval(async function () {
                            let web3 = getWeb3(chainId);
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.success('success ! your last transaction is success 👍');
                                    setUpdater(new Date());
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setUpdater(new Date());
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setUpdater(new Date());
                                    setLoading(false);
                                }
                            }
                        }, 5000);

                    }
                    else {
                        toast.error('you don\'t have enough reward for claim !');
                        setLoading(false);
                    }

                }
                else {
                    toast.error('Please select Smart Chain Network !');
                    setLoading(false);
                }
            }
            else {
                toast.error('Please Connect Wallet!');
                setLoading(false);
            }

        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }


    return (
        <div className="container mb-5">
            <div className="row mt-5">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="grid-full ">
                        <div className="text-center">
                            <h5>Your OCEANS Balance</h5>
                        </div>
                        <div className="mt-10 text-left flex flex-wrap">
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">In Wallet</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {tokenState.balance ? formatPrice(parseFloat(tokenState.balance).toFixed(3)) : 0} OCEANS
                                </div>
                            </div>
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">Staked</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {accState.totalvalue ? formatPrice(parseFloat(accState.totalvalue).toFixed(3)) : 0} OCEANS
                                </div>
                            </div>
                        </div>
                        <hr />

                        <div className="text-left flex flex-wrap">
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">
                                    Your APY
                                    <Tooltip
                                        content="Total APY based on total stake NFT's"
                                        customCss={`white-space: nowrap;`}
                                    >
                                        <FontAwesomeIcon data-iscapture="true" data-tip data-for='global' className="mx-2" icon={faCircleInfo} />
                                    </Tooltip>
                                </div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {accState.totalapy ? formatPrice(parseFloat(accState.totalapy).toFixed(3)) : 0}%
                                </div>
                            </div>
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">Last Claimed</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right" style={{ "fontSize": "10px" }}>
                                    {accState.lastClaimed > 0 ? new Date(parseInt(accState.lastClaimed * 1000)).toUTCString() : ' - '}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="mt-4 w-full flex justify-content-center">
                            <Link to="/stake-nft" className="py-2 px-4 md:text-xl md:py-3 md:px-4 buttonRed">BUY & STAKE MORE</Link>
                        </div>
                    </div>
                    <div className="grid-full mt-4">
                        {/* <div className="text-left flex flex-wrap">
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">Your APY</div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    400%
                                </div>
                            </div>
                        </div> */}
                        <div className="text-left flex flex-wrap">
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">
                                    Unlock Token
                                    <Tooltip
                                        content="Token For Unstake
                                        (*APY will reduce base on unstake amount percentage)"
                                    >
                                        <FontAwesomeIcon data-iscapture="true" data-tip data-for='global' className="mx-2" icon={faCircleInfo} />
                                    </Tooltip>
                                </div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {accState.unlockvalue ? formatPrice(parseFloat(accState.unlockvalue).toFixed(3)) : 0} OCEANS
                                </div>
                            </div>
                            <div className="mt-1 w-full flex flex-wrap">
                                <div className="text-sm sm:text-md">
                                    Reward Earning
                                    <Tooltip
                                        content="Total Earn Reward"
                                        customCss={`white-space: nowrap;`}
                                    >
                                        <FontAwesomeIcon data-iscapture="true" data-tip data-for='global' className="mx-2" icon={faCircleInfo} />
                                    </Tooltip>
                                </div>
                                <div className="flex-grow font-medium text-sm sm:text-md text-right">
                                    {accState.getEarning ? formatPrice(parseFloat(accState.getEarning).toFixed(3)) : 0} OCEANS
                                </div>
                            </div>
                            <hr />
                            <div className="mt-4 w-full flex justify-content-between">
                                <Button loading={loading} variant="none" className="py-2 px-4 md:text-xl md:py-3 md:px-4 buttonRed" onClick={(e) => handleClaimReward(e)}>Claim</Button>
                                <Link to="/unstake" className="py-2 px-4 md:text-xl md:py-3 md:px-4 buttonRed ">UnStake</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-8">

                    {accState.tokenURI && accState.tokenURI.length > 0 ? (

                        <div className="mt-4 d-flex justify-conten-center flex-wrap mb-5">
                            {
                                accState.tokenURI.map((value, index) => {
                                    let split = value.split('/').pop();
                                    let id = parseInt(split.toString().replace('.json', '')) - 1;

                                    return (
                                        <div className="w-1/3 sm:w-1/3 md:w-1/3 p-1 lg:w-1/3" key={index}>
                                            <div className="w-full d-flex baseDiv">
                                                <div className="nftCard relative rounded-t-xl sm:rounded-t-2xl rounded-b-lg sm:rounded-t-2xl sm:rounded-b-2xl">
                                                    <img src={NftList[id].image} alt="Level 1" className="nftImage" />
                                                    <div className="nftOverlay"></div>
                                                    <div className="relative bottom-0 w-full bottom-0 text-center flex flex-wrap justify-center content-center pt-1">
                                                        <div className="text-md md:text-lg font-light w-full p-1">
                                                            <p className='mb-1'>{NftList[id] ? NftList[id].name : ''}</p>
                                                            <p className="mb-1">{NftList[id] ? NftList[id].apy : ''}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    ) : (
                        <div className="grid-full">
                            <div className="stake-nft-container-empty">
                                <div>
                                    <div className="empty-icon"></div>
                                    <div className="empty-title h4">No Staked NFTs Yet</div>
                                    <div className="empty-title h7"> Start staking OceansNFT to earn interest now! </div>
                                    <Link to="/stake-nft" className="py-2 px-4 md:text-xl md:py-3 md:px-4 buttonRed mt-3">BUY & STAKE</Link>
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>
            </div>
        </div >

    )
}
