import { useEffect, useState } from "react"
import { useWeb3React } from "@web3-react/core";
import tokenAbi from '../json/token.json';
// import oceanNftAbi from '../json/oceanNft.json';
import { contract, DEFAULT_CHAIN } from "./constant";
import { MulticallContractWeb3 , multiCallContractConnect  } from "../hooks/useContracts";
import { getWeb3 } from "../hooks/connectors";
import { toast } from "react-toastify";


export const useNftAccountStats = (updater) => {
    let { account, chainId } = useWeb3React();
    let web3 = getWeb3(DEFAULT_CHAIN);
    let busdContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].BUSD_ADDRESS);
    let oceansContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].OCEANS_ADDRESS);
    const [stats, setStats] = useState({
        busdBal: 0,
        busdApprove: false,
        busdDecimals: 0,
        balance: 0,
        getEthBalance: 0
    });

    const mc = MulticallContractWeb3(DEFAULT_CHAIN);
    let multiCallContract = multiCallContractConnect(DEFAULT_CHAIN)


    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    busdContract.methods.balanceOf(account), //0
                    busdContract.methods.allowance(account, contract[DEFAULT_CHAIN].OCEANS_NFT_ADDRESS), //1
                    busdContract.methods.decimals(), //2,
                    oceansContract.methods.balanceOf(account), //0
                    oceansContract.methods.decimals(), //1
                    multiCallContract.methods.getEthBalance(account), //1
                ]);

                setStats({
                    busdBal: data[0] / Math.pow(10, data[2]),
                    busdApprove: parseFloat(data[1] / Math.pow(10, data[2])) > 500000 ? true : false,
                    busdDecimals: data[2],
                    balance: data[3] / Math.pow(10, data[4]),
                    getEthBalance: data[5] / Math.pow(10, 18)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (mc && account) {
            fetch();
        }
        else {
            setStats({
                busdBal: 0,
                busdApprove: false,
                busdDecimals: 0,
                balance: 0,
                getEthBalance: 0
            })
        }
        // eslint-disable-next-line
    }, [updater, account, chainId]);

    return stats;
}
