import localStorage from "local-storage";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6) }...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  56 : {
    OCEANS_ADDRESS: "0x2A54F9710ddeD0eBdde0300BB9ac7e21cF0E8DA5",
    DISTRIBUTOR_ADDRESS: "0xb9f54a4a4a23981cd4e068a4f705cb505b221334",
    WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    BUSD_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    WBNB_USDT_LP_ADDRESS: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
    USDT_BUSD_LP_ADDRESS:"0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
    OCEANS_BNB_LP_ADDRESS: "0x034a9fa1e7cb4c16439c559505858ae0932f126d",
    TREASURY_ADDRESS: "0x97e0ceb36e37646e58b05d7b5a461e05c0906417",
    MULTICALL_ADDRESS : "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116",
    OCEANS_NFT_ADDRESS : "0x0f4F5c49C7304bFC66083eD6e63Ddb3f67C0eC64",
    OCEANS_STAKING : "0xB3b46Ce5135955c8c420181230AACaf601c8fDC4",
    PANCAKE_ROUTER : "0x10ed43c718714eb63d5aa57b78b54704e256024e"
  },
  // 97:{
  //   OCEANS_ADDRESS : "0x3034405c89b321bcbf81a738ea2f55e0fa34f88a",
  //   OCEANS_NFT_ADDRESS : "0x748C228825aB1f16E22AAD6dE62F311db7555812",
  //   OCEANS_STAKING : "0x3b16a1D8AFB5a4Bf0C7748a35d80449FF2B9eb36",
  //   BUSD_ADDRESS : "0x78867bbeef44f2326bf8ddd1941a4439382ef2a7",
  //   MULTICALL_ADDRESS : "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"

  // },
  'default': {
    OCEANS_ADDRESS: "0x2A54F9710ddeD0eBdde0300BB9ac7e21cF0E8DA5",
    DISTRIBUTOR_ADDRESS: "0xb9f54a4a4a23981cd4e068a4f705cb505b221334",
    WBNB_ADDRESS: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    BUSD_ADDRESS: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    WBNB_USDT_LP_ADDRESS: "0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE",
    USDT_BUSD_LP_ADDRESS:"0x7EFaEf62fDdCCa950418312c6C91Aef321375A00",
    OCEANS_BNB_LP_ADDRESS: "0x034a9fa1e7cb4c16439c559505858ae0932f126d",
    TREASURY_ADDRESS: "0x97e0ceb36e37646e58b05d7b5a461e05c0906417",
    MULTICALL_ADDRESS : "0x2cc8fe3e04d7b1edaeb42e2e73f78c4cac074116",
    OCEANS_NFT_ADDRESS : "0x0f4F5c49C7304bFC66083eD6e63Ddb3f67C0eC64",
    OCEANS_STAKING : "0xB3b46Ce5135955c8c420181230AACaf601c8fDC4",
    PANCAKE_ROUTER : "0x10ed43c718714eb63d5aa57b78b54704e256024e"
  }

}

export const DEFAULT_CHAIN = 56;
export const DEFAULT_CHAIN_TEST = 97;
export const FEES_PER = 90;



export function transactionHistory(hash,type,amount,currency) {
  
  let last_history = localStorage.get('txhistory');
  if(last_history !== null){
    let count = last_history.length;
    if(count > 8){
      last_history.shift();
    }
  }
  localStorage.set('txhistory',last_history === null  ? [{ hash, type, amount , currency }] : [...last_history,{ hash, type, amount , currency }]);
}

